import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, validationerror } from "./assets";
import React from 'react';
import { Box, Typography } from '@material-ui/core';
export type token = string | null;
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from 'react-toastify';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorAvailability: HTMLElement | null;
  activeStep:number,
  token: token;
  responseData: any;
  customer_code: string,
  status_of_order:string,
  customer_name: string,
  total_items:string,
  order_date:string,
  branch_name:string,
  invoice_address:string,
  sales_person: string,
  delivery_address: string,
  pricelist: string,
  payment_terms:string,
  terms_and_conditions:string,
  margin:string,
  total:string,
  invoice_status:string,
  archived:boolean,
  customerNameList:string[],
  productDataList:any[],
  quantity:string,
  tableData: Array<
    {
      id: string,
      quotation_id: string,
      product_name: string,
      product_description: string,
      qty: string,
      units: string,
      price_in_units: string,
      tax: string,
      discount: number,
      sub_total: string,
      onHandQty:string,
      forecastedQty:string,
    }>,

  saveClick: boolean;
  deleteDialog:boolean;
  deleteRowId:number;
  deleteQuotationId: string;
  deleteorderLineId: string;
  
  anchorElPrint: HTMLElement | null;
  anchorElSetting: HTMLElement | null;
  quotation_id: string;
  quotation_number: string
  // Customizable Area End
}

export interface Option {
  value: string;
  label: string;
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Ordercreation2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCustomerNameAPI:string="";
  getProductListingAPI:string="";
  createQuotationMethodAPI: string = "";
  getSingleOrderAPI: string = ""; 
  deleteOrderLinesAPI: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage), 
      getName(MessageEnum.RestAPIRequestMessage), 
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorAvailability:null,
      activeStep:0,
      token: "",
      responseData: [],
      customer_code:"",
      status_of_order: "",
      customer_name:"",
      total_items:"",
      order_date: "",
      branch_name: "",
      invoice_address: "",
      sales_person:"",
      delivery_address: "",
      pricelist:"",
      payment_terms: "",
      terms_and_conditions:"",
      margin: "15%",
      total: "1000",
      invoice_status:"",
      archived:false,
      customerNameList:[],
      productDataList:[],
      quantity:"",
      tableData: [
        {
          id:"",
          quotation_id:"",
          product_name: "",
          product_description: "",
          qty:"",
          units: "",
          price_in_units: "",
          tax: "",
          discount: 0,
          sub_total: "",
          onHandQty:"",
          forecastedQty:"",
        },
      ],
      saveClick: false,
      deleteDialog:false,
      deleteRowId:0,
      deleteQuotationId: "",
      deleteorderLineId: "",

      anchorElPrint: null,
      anchorElSetting: null,
      quotation_id: "",
      quotation_number: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.receiveApi(apiRequestCallId, responseJson);
      }
      runEngine.debugLog(
        "API Message Recived", message
      );
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  invoiceAddressOptions: Option[] = [
    { value: 'IAddress1', label: 'IAddress1' },
    { value: 'IAddress2', label: 'IAddress2' }
  ];

  deliveryAddressOptions: Option[] = [
    { value: 'DAddress1', label: 'DAddress1' },
    { value: 'DAddress2', label: 'DAddress2' }
  ];

  salesPersonOptions: Option[] = [
    { value: 'Sales Person 1', label: 'Sales Person 1' },
    { value: 'Sales Person 2', label: 'Sales Person 2' }
  ];

  pricelistOptions: Option[] = [
    { value: 'Price 1', label: 'Price 1' },
    { value: 'Price 2', label: 'Price 2' }
  ];

  paymentTerms: Option[] = [
    { value: 'Term 1', label: 'Term 1' },
    { value: 'Term 2', label: 'Term 2' },
  ];

   PrintOptions = [
    { name: "Quotation/Order" },
    { name: "Pickup and Return reciepts" },
  ];

  SettingOptions = [
    { name: "Export" },
    { name: "Delete" },
    { name: "Create Invoices" },
  ];

  async componentDidMount() {
    const token = await getStorageData("TOKEN")
    this.setState({ token: token });
    if (!token) {
      this.props.navigation.navigate("EmailAccountLoginStyles")
    }
    else {
      const id = this.props.navigation.getParam("id")
      if (id) {
        this.fetchSingleOrderData(id)
      }
      this.fetchCustomerNameData();
      this.fetchProductListData();
    }
  }

  receiveApi = (
    apiRequestCallId: string,
    responseJson: {
      data: object[];
      meta: { total_pages: number; total_counts: number };
    }
  ) => {
    if (apiRequestCallId === this.getCustomerNameAPI) {
      this.handleGetCustomerNameResponse(
        responseJson.data
      )
    }
    
    if (apiRequestCallId === this.getProductListingAPI) {
      this.handleGetProductListResponse(
        responseJson.data
      )
    }
    if (apiRequestCallId === this.getSingleOrderAPI) {
      this.handleGetSingleOrderResponse(
        responseJson.data
      );
    }
    if (apiRequestCallId === this.deleteOrderLinesAPI) {
      const id = this.props.navigation.getParam("id")
      this.fetchSingleOrderData(id)
    }
    if (apiRequestCallId === this.createQuotationMethodAPI) {
      this.handleCreateQuotationMethodAPI(responseJson)
    }
  };

  handleGetSingleOrderID = (response: any) => {
    const createdId = response.info.data.id;
    this.setState({
      quotation_id: createdId
    })
    this.props.navigation.navigate("OrdercreationView", { id: createdId });
    this.fetchSingleOrderData(createdId)
  }

  handleGetCustomerNameResponse = (response: any,) => {
      let nameList:string[]=[];
      response?.map((item: any)=>{
        nameList.push(item.attributes.first_name)
        this.setState({
          customerNameList: nameList,
        })
      })
  }

  handleCreateQuotationMethodAPI =(responseJson:any)=>{
    if (responseJson.message) {
      toast.success(responseJson.message)
      this.props.navigation.navigate("Orderlisting")
    } 
    else if(Array.isArray(responseJson.errors)){
      toast.error(responseJson.errors[0])
    }
    else if(responseJson.error){
      toast.error(responseJson.error)
    }
    else
      return 
  }

  handleGetSingleOrderResponse = (
    data: any,
  ) => {
    if (data && data.data && data.data.attributes) {
      const orderData = data.data.attributes;
      this.setState({
        customer_code: orderData.customer_code,
        status_of_order: orderData.status_of_order,
        customer_name: orderData.customer_name,
        total_items: orderData.total_items,
        order_date: this.handleDate(orderData.order_date),
        branch_name: orderData.branch_name,
        invoice_address: orderData.invoice_address,
        sales_person: orderData.sales_name,
        delivery_address: orderData.delivery_address,
        pricelist: orderData.pricelist,
        payment_terms: orderData.payment_terms,
        terms_and_conditions: orderData.terms_and_conditions,
        margin: orderData.margin,
        total: orderData.total,
        invoice_status: orderData.invoice_status,
        archived: orderData.archived,
        tableData: orderData.order_lines,
        quotation_number: orderData.quotation_no,
      })
    }
  };

  handleAddRow = () => {
    const newRow = {
      id: "",
      quotation_id: "",
      product_name: "",
      product_description: "",
      qty: "",
      units: "",
      price_in_units: "",
      tax: "",
      discount: 0,
      sub_total: "",
      onHandQty: "",
      forecastedQty: "",
    };

    this.setState({
      tableData: this.state.tableData.concat(newRow),
    })
  };

  handleDeleteRow = (rowIndex: number, quotation_id: string, order_line_id: string) => {
      this.setState({
        deleteDialog: true,
        deleteRowId: rowIndex,
        deleteQuotationId: quotation_id,
        deleteorderLineId: order_line_id,
      })
  };
  handleDialogCancel = () => {
    this.setState({
      deleteDialog: false,
    })
  }

  onDeleteOrderLines = () => {
    const { deleteRowId, deleteQuotationId, deleteorderLineId, token } = this.state;
    if (deleteorderLineId=="") {
      this.setState((prevState) => ({
        tableData: prevState.tableData.filter((_, i) => i !== deleteRowId),
      }));
    }
    else{
      this.deleteOrderLinesAPI = this.apiCallHandle(
        token,
        `/bx_block_order_management/delete_orderline?quotation_id=${deleteQuotationId}&order_line_id=${deleteorderLineId}`,
        "DELETE",
      );
    }
    this.setState({
      deleteDialog: false,
    })

  }

  handleProductSelect = (index: number, selectedProduct: any) => {
    const selectedProductData = this.state.productDataList.find(
      (product) => product.name === selectedProduct
    );

    if (selectedProductData) {
      this.setState((prevState) => {
        const updatedTableData = [...prevState.tableData];
        updatedTableData[index] = {
          ...updatedTableData[index],
          product_name: selectedProductData.name,
          product_description: selectedProductData.purchaseDescription,
          qty: selectedProductData.qTYOnHand,
          units: selectedProductData.inhandUnits,
          price_in_units: selectedProductData.price,
          tax: "",
          discount: 0,
          sub_total: "",
        };
        return { tableData: updatedTableData };
      });
    }
  };


  getTableKey = (data: any) => {
    const requiredData: any = [];
    data.map((item: any) => {
      const id = item.id;
      const attributes = item.attributes;
      const generalInfo = attributes.general_info;
      const purchases = attributes.purchases;
      const productInfo: any = {
        id,
        name: attributes?.product_name || "",
        purchaseDescription: purchases?.purchase_description,
        inhandUnits: generalInfo.purchased_uom,
        qTYOnHand: purchases?.qty || 0,
        forecastedQTY: purchases?.forecasted_qty || 0,
        price: generalInfo?.sales_price || "",
      };
      requiredData.push(productInfo);
    })
    return requiredData;
  }

  handleGetProductListResponse = (response: any) => {
    const data = this.getTableKey(response);
    this.setState({
      productDataList: data
    })
  }

  handleValueChange = (e: any, name: string) => {
    this.setState({
       ...this.state,
        [name]: e.target.value
    })
  }

  handleStepperNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleStepperBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleStepperReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  handleQty=(value:string)=>{
    this.setState({
      quantity: value,
    })
  }

  handleResetOrder = () => {
    this.setState({
      anchorAvailability: null,
      activeStep: 0,
      token: "",
      responseData: [],
      customer_code: "",
      status_of_order: "",
      customer_name: "",
      total_items: "",
      order_date: "",
      branch_name: "",
      invoice_address: "",
      sales_person: "",
      delivery_address: "",
      pricelist: "",
      payment_terms: "",
      terms_and_conditions: "",
      margin: "",
      total: "",
      invoice_status: "",
      archived: false,
      customerNameList: [],
      productDataList: [],
      tableData: [
        {
          id: "",
          quotation_id: "",
          product_name: "",
          product_description: "",
          qty: "",
          units: "",
          price_in_units: "",
          tax: "",
          discount: 0,
          sub_total: "",
          onHandQty: "",
          forecastedQty: "",
        },
      ],
    })
  }

  handleCheckFields=()=>{
    if (
      this.isStringNullOrBlank(this.state.customer_code) ||
      this.isStringNullOrBlank(this.state.status_of_order) ||
      this.isStringNullOrBlank(this.state.customer_name) ||
      this.isStringNullOrBlank(this.state.total_items) ||
      this.isStringNullOrBlank(this.state.order_date) || 
      this.isStringNullOrBlank(this.state.branch_name) ||
      this.isStringNullOrBlank(this.state.invoice_address) ||
      this.isStringNullOrBlank(this.state.sales_person) ||
      this.isStringNullOrBlank(this.state.delivery_address) ||
      this.isStringNullOrBlank(this.state.pricelist) ||
      this.isStringNullOrBlank(this.state.payment_terms) ||
      this.isStringNullOrBlank(this.state.margin) ||
      this.isStringNullOrBlank(this.state.total)
      ) {
      return false;
    }
    return true;
  }


  isStringNullOrBlank(str: string) {
    return str === null || str === '' || /^\s*$/.test(str);
  }


  handleAvailabilityClickAway = () => {
    this.setState({
      anchorAvailability: null
    });
  };

   handleSettingsClickAway = () => {
    this.setState({ 
      anchorElSetting: null 
    });
  };

  handlePrintClickAway = () => {
    this.setState({ 
      anchorElPrint: null 
    });
  };

  handleClickAvailability = (e: any) => {
    this.setState({ anchorAvailability: this.state.anchorAvailability ? null : e.currentTarget, });
  };

  handleClickSetting = (e: any) => {
    this.setState({anchorElSetting: this.state.anchorElSetting ? null : e.currentTarget,});
  };

  handleClickPrint = (e: any) => {
    this.setState({ anchorElPrint: this.state.anchorElPrint ? null : e.currentTarget, });
  };

  handleDiscard = () => {
    this.props.navigation.navigate("Orderlisting")
  }

  handleErrorMessage = (type: string, show: boolean = false) => {
    if (this.state.saveClick || show) {
      let flabel: any = '';
      switch (type) {
        case 'customer_code': flabel = this.showErrorMessage(this.state.customer_code, 'Please enter valid customer code.'); break;
        case 'status_of_order': flabel = this.showErrorMessage(this.state.status_of_order, 'Please enter valid status of order.'); break;
        case 'customer_name': flabel = this.showErrorMessage(this.state.customer_name, 'Please select valid customer name.'); break;
        case 'total_items': flabel = this.showErrorMessage(this.state.total_items, 'Please enter total items'); break;
        case 'order_date': flabel = this.showErrorMessage(this.state.order_date, 'Please enter valid order date (DD/MM/YYYY)'); break;
        case 'branch_name': flabel = this.showErrorMessage(this.state.branch_name, 'Please enter valid branch name.'); break;
        case 'invoice_address': flabel = this.showErrorMessage(this.state.invoice_address, 'Please select valid invoice address.'); break;
        case 'sales_person': flabel = this.showErrorMessage(this.state.sales_person, 'Please select valid sales person.'); break;
        case 'delivery_address': flabel = this.showErrorMessage(this.state.delivery_address, 'Please select valid delivery address.'); break;
        case 'pricelist': flabel = this.showErrorMessage(this.state.pricelist, 'Please select valid pricelist'); break;
        case 'payment_terms': flabel = this.showErrorMessage(this.state.payment_terms, 'Please select valid payment terms'); break;
        default:
          break;

      }
      return this.handleLabel(flabel)
    } else {
      return ''
    }

  }

  showErrorMessage = (state: any, msg: string) => {
    return !state && msg;
  }

  handleLabel = (flabel: any) => {
    return <>
      {
        flabel ? this.handleErrorLabel(flabel) : flabel
      }
    </>
  }

  handleErrorLabel = (flabel: any) => {
    return <Box className='flex relative' >
      <img className='error-text-img' src={validationerror} />
      <Typography className='error-text'>{flabel}</Typography>
    </Box>
  }

  fetchProductListData = () => {
    const token = this.state.token;
    this.getProductListingAPI = this.apiCallHandle(
      token,
      `/bx_block_productquickview/products?page=1&per_page=10`,
      "GET"
    );
  };

  fetchCustomerNameData = () => {
    const token = this.state.token;
    this.getCustomerNameAPI = this.apiCallHandle(
      token,
      `/account_block/accounts/customer_list_dropdown`,
      "GET"
    );
  };

  fetchSingleOrderData = (id: string) => {
    const token = this.state.token;
    this.getSingleOrderAPI = this.apiCallHandle(
      token,
      `/bx_block_order_management/quotations/${id}`,
      "GET"
    );
  };

  errorTextClass = (field: any, value: any) => {
    if (this.handleErrorMessage(field) && !value) {
      return 'error-text1';
    } else {
      return 'main-color';
    }
  }

  errorFocusClass = (field: any, value: any) => {
    if (this.handleErrorMessage(field) && !value) {
      return 'error-focus';
    } else {
      return '';
    }
  }

  mainErrorBox = () => {
    return <Box className={`flex error-main-box ${!(this.state.saveClick && ((!this.handleCheckFields()))) && 'display-none'}`}>
      <img src={validationerror} />
      <Typography className='error-main-text'>Some of the fields are incorrect or invalid. Please enter the valid details to continue.</Typography>
    </Box>
  }

  handleSave = () => {
    
    const token = this.state.token;
    const data = {
      "data": {
        "customer_code": this.state.customer_code,
        "customer_name": this.state.customer_name,
        "order_date": this.state.order_date,
        "invoice_address": this.state.invoice_address,
        "delivery_address": this.state.delivery_address,
        "payment_terms": this.state.payment_terms,
        "sales_name": this.state.sales_person,
        "status_of_order": this.state.status_of_order,
        "total_items": this.state.total_items,
        "pricelist": this.state.pricelist,
        "branch_name": this.state.branch_name,
        "terms_and_conditions": this.state.terms_and_conditions,
        "margin": this.state.margin,
        "total": this.state.total,
        "invoice_status": this.state.invoice_status,
        "archived": this.state.archived,
        "order_lines_attributes": this.state.tableData,
      }
    }

    this.setState({
      saveClick: true
    })


    if (this.handleCheckFields()) {
      const id = this.props.navigation.getParam("id")
      let endPoint = `/bx_block_order_management/quotations`
      let method = "POST"
      if (id) {
        endPoint += `/${id}`
        method = "PUT"
      }
      this.createQuotationMethodAPI = this.apiCallHandle(
        token,
        endPoint,
        method,
        data
      );
    }
  };


  apiCallHandle = (token: token, endPoint: string, method: string, body?: object) => {
    const header = {
      "Content-Type": "application/json", token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  handleDate = (givenDate: string) => {
    const date = new Date(givenDate);
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate;
  };
  // Customizable Area End
}
