import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  MenuItem,
  Divider,
  Popper,
  ClickAwayListener,
  MenuList,
  Switch
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider} from "@material-ui/core/styles";
import Sidebar from "../../../components/src/Sidebar.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { Link }  from 'react-router-dom';
const {cross,check ,settings } = require("./assets");

const theme = createTheme({
  typography: {
    h6: {fontWeight: 500,},
    subtitle1: {margin: "20px 0px",},
  },
  palette: {
    primary: {contrastText: "#fff",main: "#2A6395",},
  },
});


// Customizable Area End

import BankAccountCreationController, {
  Props,
  configJSON,
} from "./BankAccountCreationController";

export default class BankAccountCreationView extends BankAccountCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider 
      theme={theme}>
        <Box 
          style={webStyle.gridMainLayout}>
          <Box 
            style={webStyle.gridsideBar}>
            <Sidebar /></Box>
          <Box 
            className="gridcontent" 
            style={webStyle.gridcontent}>
            <AppHeader 
              pageTitle={configJSON.ordermanagement} />

            <Box className="parent-div" >
              <style>
                {`
                  .MuiSelect-selectMenu{
                    border: 1px solid #e3e3e3;
                  }
                  .MuiInputBase-root{
                    border-radius: 5px;
                  }
                  .MuiInput-underline:after {
                    border-bottom: 0px !important;
                  }
                  .MuiInputAdornment-positionEnd{
                    padding : 10px;
                  }
                  .MuiInputBase-input{
                    border: 0px !important;
                  }
                  .MuiChip-root{
                    color:#000 !important;
                    background-color:#e0e0e0 !important;
                  }
                  .MuiFormControl-fullWidth{
                    width: 90%;
                  }
                  .MuiIconButton-root:hover{
                    background-color: transparent !important;
                  }
                  .MuiPaper-elevation1 {
                    box-shadow: none;
                  }
                  .productnameCell {
                      & .MuiInputBase-root {
                        outline: none;
                      }
                  }
                  .MuiGrid-spacing-xs-3{
                    width: calc(100% + 50px);
                  }
                  .MuiTableCell-alignLeft{
                    padding:8px;
                  }
                  .MuiSwitch-root {
                    width: 42px !important;
                    height: 26px !important;
                    padding: 0 !important;
                    margin: 8px !important;
                  }

                  .MuiSwitch-track {
                    border-radius: 13px !important;
                    background-color: #DCDCDC !important;
                    opacity: 1 !important;
                    transition: background-color 300ms ease-in-out, border 300ms ease-in-out !important;
                  }

                  .MuiSwitch-thumb {
                    width: 21px !important;
                    height: 21px !important;
                  }

                  .MuiSwitch-switchBase {
                    padding: 1px !important;
                    color : #DCDCDC !important;
                    background-color: #ffffff !important;
                    top: 2.5px !important;
                    left: 3px !important;
                  }
                  
                  .MuiSwitch-switchBase.Mui-checked {
                    transform: translateX(16px) !important;
                    color: #2A6395 !important;
                  }

                  .Mui-checked {
                    background-color: #ffffff !important;
                  }
                  
                  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
                    background-color: #2A6395 !important;
                    opacity: 1 !important;
                    border: none !important;
                  }
                  
                  .MuiSwitch-switchBase:focus-visible .MuiSwitch-thumb {
                    color: #2A6395 !important;
                    border: 6px solid #ffffff !important;
                  }
                `}
              </style>
              <Grid container spacing={0} style={{ display: "flex", flexDirection: "column", marginTop: "25px" }}>
                <Grid item sm={3} lg={12} md={12}>
                  <Box style={{ paddingRight: "25px", height: "50px", alignItems: "center" }} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}  >
                    <Box sx={{ width: "100%" }}  display={"flex"} flexDirection={"row"}  justifyContent={"space-between"} >
                      <Typography>
                        <Link to = "/BankAccountListing" 
                        className="customer bold-text" 
                        style={{ textDecoration: 'none' }} >
                        {configJSON.bankAccountHeading} /
                        </Link>
                        <span style={{ color: "grey" }}>New</span>
                      </Typography>
                      <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>

                        <ClickAwayListener 
                          onClickAway={this.handleSettingsClickAway} 
                          data-test-id="settingClickAway"
                          >
                          <div>
                            <Button
                              data-test-id="settingPopper"
                              style={webStyle.buttonComponentSettings}
                              onClick={this.handleClickSetting}
                            ><img src={settings} /></Button>
                            <Popper
                              style={webStyle.settingsPopper}
                              open={Boolean(this.state.anchorElSetting)}
                              transition
                              placement="bottom-end"
                              anchorEl={this.state.anchorElSetting}
                            >

                              <MenuList style={{ padding: 0 }}>
                                {this.SettingOptions.map((item) => ( <div style={{ borderBottom: "1px solid #F3F3F3" }}>
                                    <MenuItem
                                      data-test-id="closedMenu-1"
                                      key={item.name}
                                      data-value={item.name}
                                      style={webStyle.popperItem}
                                    >{item.name}</MenuItem>
                                  </div>
                                ))}
                              </MenuList>
                            </Popper>
                          </div>
                        </ClickAwayListener>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Divider style={webStyle.divider} />
                  <Box style={{padding: "0px !important", width: "100%" }}>
                    <Box className="form-div" style={{width: "95%"}}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3} lg={5}>
                                  <Typography variant="body2" style={{whiteSpace: "nowrap"}}>Account Number<span className='required'>*</span></Typography>
                                </Grid>
                                <Grid item xs={3} lg={5}>
                                <Typography variant="body2" style={{ color: "gray" }}>{this.state.account_number}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3} lg={5}>
                                  <Typography variant="body2" style={{whiteSpace: "nowrap"}}>Bank<span className='required'>*</span></Typography>
                                </Grid>
                                <Grid item xs={3} lg={5}>
                                <Typography variant="body2" style={{ color: "gray" }}>{this.state.bank}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3} lg={5}>
                                  <Typography variant="body2" style={{whiteSpace: "nowrap"}}>CLABE<span className='required'>*</span></Typography>
                                </Grid>
                                <Grid item xs={3} lg={5}>
                                <Typography variant="body2" style={{ color: "gray" }}>{this.state.clabe}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3} lg={5}>
                                  <Typography variant="body2" style={{whiteSpace: "nowrap"}}>ABA/Routing<span className='required'>*</span></Typography>
                                </Grid>
                                <Grid item xs={3} lg={5}>
                                <Typography variant="body2" style={{ color: "gray" }}>{this.state.aba_routing}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2} style={{alignItems: "baseline"}}>
                                <Grid item xs={3} lg={3}>
                                  <Typography variant="body2" style={{whiteSpace: "nowrap"}}>Send Money</Typography>
                                </Grid>
                                <Grid item xs={3} lg={3} style={{marginBottom:"10px"}}>
                                  <Switch
                                    data-test-id="toggleSwitch"
                                    icon={<img alt="cross" src={cross} width={'100%'} height={'100%'} />}
                                    checkedIcon={<img alt="check" src={check} width={'100%'} height={'100%'} />}
                                    checked={this.state.send_money}
                                    disabled={true}
                                    color="primary"
                                    name={`switch`}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3} lg={5}>
                                  <Typography variant="body2" style={{whiteSpace: "nowrap"}}>Account Holder<span className='required'>*</span></Typography>
                                </Grid>
                                <Grid item xs={3} lg={5}>
                                <Typography variant="body2" style={{ color: "gray" }}>{this.state.account_holder}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3} lg={5}>
                                  <Typography variant="body2" style={{whiteSpace: "nowrap"}}>Company</Typography>
                                </Grid>
                                <Grid item xs={3} lg={5}>
                                <Typography variant="body2" style={{ color: "gray" }}>{this.state.company}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3} lg={5}>
                                  <Typography variant="body2" style={{whiteSpace: "nowrap"}}>Currency<span className='required'>*</span></Typography>
                                </Grid>
                                <Grid item xs={3} lg={5}>
                                <Typography variant="body2" style={{ color: "gray" }}>{this.state.currency}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={3} lg={5}>
                                  <Typography variant="body2" style={{whiteSpace: "nowrap"}}>Account Holder Name<span className='required'>*</span></Typography>
                                </Grid>
                                <Grid item xs={3} lg={5}>
                                <Typography variant="body2" style={{ color: "gray" }}>{this.state.account_holder_name}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Box>
                    
                  </Box>
              </Grid>
            </Box>
            <Box sx={webStyle.actionBtnStyle}>
              <Button 
              onClick={this.handleRedirectionToCreateScreen}
              variant="contained" 
              data-test-id="createBtn" 
              style={webStyle.createBtn} 
              >Create
              </Button>
              <Button
                type="submit"
                onClick={this.handleRedirectionToEditScreen}
                style={webStyle.saveBtn}
                data-test-id="saveBtn"
              >
                Edit
              </Button>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    background: "#fff",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    display: "flex",
    alignItems: "center",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "100px",
  },
  gridMainLayout: {
    display: "flex",
  },
  gridsideBar: {
    display: "flex",
    minHheight: "100%",
    transition: "all 0.3s",
  },
  buttonStyle: {
    border: "none",
    height: "45px",
    backgroundColor: "rgb(98, 0, 238)",
    marginTop: "40px",
    width: "100%",
  }, 
  actionBtnStyle: {
    marginBlock: "40px",
    gap: 8,
    float: "right",
    display: "flex",
  },
  gridcontent: {
    flexGrow: 9,
    padding: "0 20px",
    width: "77%",
  },
  createBtn: {
    backgroundColor: "white",
  },
  saveBtn: {
    color: "#ffffff",
    backgroundColor: "rgba(9, 65, 115, 0.77)",
  },
  buttonComponentSettings: {
    fontSize: "12px",
    padding: "8px 8px",
    backgroundColor: "#e1e1e1",
    borderRadius: "4px",
    height: "100%",
    marginRight:"20px",
    border: "none",
    cursor: "pointer",
    alignItems: "center",
    color: "black",
    display: "flex",
  },
  divider: {
    backgroundColor: "#E3E3E3",
    marginTop: "20px",
    marginBottom: "20px",
  },
  popperItem: {
    fontSize: "small",
    borderBottom: "1px solid #F3F3F3 !important",
  },
  availabilityPopper: {
    borderRadius: 4,
    padding: 0,
    zIndex: theme.zIndex.tooltip,
    width: "fit-content",
    backgroundColor: "#ffffff",
    marginTop: "4px",
    border: "1px solid #ccc",
  },
  productCardBox: {
    gap: 40,
    display: "flex",
  },
  popperItemBlue:{
    fontSize: "18px",
    background:"#2A6395",
    borderBottom: "1px solid #F3F3F3 !important",
  },
  graphpopper: {
    margin: "10",
    minWidth: "30px",
    height: "30px",
  },
  activityIconWrapper: {
    gap: 8,
    display: "flex",
    alignItems: "center",
  },
  boldFont:{
    marginLeft:"5%",
    fontSize: "22px",
    fontWeight: 500,
  },
  printPopper: {
    zIndex: theme.zIndex.tooltip,
    borderRadius: 4,
    padding: 0,
    width: "fit-content",
    backgroundColor: "#ffffff",
    marginTop: "4px",
    border: "1px solid #ccc",
  },
  settingsPopper: {
    padding: 0,
    backgroundColor: "#ffffff",
    marginTop: "4px",
    borderRadius: 4,
    width: "fit-content",
    border: "1px solid #ccc",
    zIndex: theme.zIndex.tooltip,
  },
  sentBtn: {
    gap:10,
    display: "flex",
  },
  btnBar: {
    justifyContent: "space-between",
    margin: "20px",
    display: "flex",
    flexDirection: "row",
  },
};
// Customizable Area End
