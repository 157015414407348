// Customizable Area Start
import React from 'react';
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { delrow, paginationarrow } from '../assets';
import StorageProvider from "../../../../framework/src/StorageProvider";
import { toast } from 'react-toastify';
export const configJSON = require("../../../settings2/src/config");
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
    loading:boolean;
    token:string|null;
    loadingUpload:boolean;
    loadingExport:boolean;

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ExportFavouriteController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetexportCallId!: string;
  addImportApiCallId!:string;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),

      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      loading:false,
      loadingUpload:false,
      loadingExport:false,
      token:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start

    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({...this.state,loading:false,loadingUpload:false,loadingExport:false});
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {

        if (apiRequestCallId === this.apigetexportCallId) {
          this.apiResponseDownload(responseJson)
        }
        if (apiRequestCallId === this.addImportApiCallId) {
          this.apiSaveUOM(responseJson)
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const getToken= await StorageProvider.get("TOKEN")
    this.setState({
      token:getToken
    })
  }
 
  handleCancel=()=>{
    this.props.navigation.navigate("UomCategorieslistview");
  }
  
   apiResponseDownload = (responseJson: { download_link: string; error: any[]; }) => {
    if (responseJson.download_link) {
      const anchor = document.createElement('a');
      anchor.href = responseJson.download_link;
      anchor.download = '';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } else if (responseJson.error) {
      toast.error(responseJson.error[0] || 'An unexpected error occurred');
    } else {
      return
    }
  };

  apiSaveUOM=(responseJson:any)=>{
    if(responseJson.message	){
      toast.success(responseJson.message)
      this.handleCancel();
    }
    else if(responseJson.error){
      toast.error(responseJson.error[0])
    }else
    return 
  }

  handleFileUpload=(event: React.ChangeEvent<HTMLInputElement>)=>{
    const file = event.target.files?.[0];
    if (file) {
      console.log("Selected file:", file);
      this.saveImportData(file)
    }
  }

  saveImportData = (file: any) => {
    this.setState({...this.state,loadingUpload:true});
    let formdata:any = new FormData();
    formdata.append("file",file);   

      const header = {
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addImportApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.importUomUrl}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }

  handleDownload=()=>{
    this.setState({...this.state,loadingExport:true});
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":this.state.token
    };

    this.apigetexportCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.downloadExportuomUrl}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
