import React from 'react';
import { Box, Button, FormControl, Grid, MenuItem, Select, TextField, Typography, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { AppHeader } from '../../../../components/src/AppHeader.web';
import Sidebar from '../../../../components/src/Sidebar.web';
import { validationerror, addrow, delrow } from '../../../cfcustomersalesreports3/src/assets';
import { ToastContainer } from 'react-toastify';
import { EditUoMController, Props } from './EditUoMCategoriesController.web';
import { ExpandMore } from '@material-ui/icons';
const configJSON1 = require('./config');
const configJSONUtils = require('../config');

export default class EditUoMCategories extends EditUoMController {
    constructor(props: Props) {
        super(props);
    }
    expandMore = () => (
        <ExpandMore />
    )

    getText = (data: any) => {
        return <Typography>{data}</Typography>
    }

    getRatioText = (ratio: any) => {
        return <Typography>{ratio}</Typography>
    }

    getInputRatio = (ratio: number, index: number, type: string) => {
        return <TextField data-test-id="uomCategoryInput1"
            className={`form-input width-100 ${type == 'ratio' ? createuomwebStyle.ratioStyle : createuomwebStyle.roundingStyle}`}
            value={ratio}
            inputProps={{
                style: type == 'ratio' ? createuomwebStyle.ratioStyle : createuomwebStyle.roundingStyle
            }}
            onChange={(e: any) => this.handleMeasureUnitsDetails(e, index, type)}
            name="category" type="text" />
    }

    getRatioColumn = (uom_type: string, ratio: any, index: number, classes: any, p1: string, p2: string) => {
        let flabel: any = '';
        switch (uom_type) {
            case configJSONUtils.uomOption1: flabel = this.getRatioText(p1); break;
            case '': flabel = this.getRatioText(''); break;
            case 'Smaller than the reference Unit of Measure for this category':
            case 'Bigger than the reference Unit of Measure for this category':
                flabel = this.getInputRatio(ratio, index, p2);
                break;
            default:
                break;
        }
        return flabel;
    }

    uomOption = () => {
        const { uomOptions } = this.state;
        if (!Array.isArray(uomOptions)) {
            return null;
        }
        return uomOptions.map((ddl: any) => (
            <MenuItem key={ddl?.id} value={ddl?.attributes?.uom}>
                {ddl?.attributes?.uom}
            </MenuItem>
        ));
    }

    render() {
        return (
            <Box style={createuomwebStyle.adduomMainLayout}>
                <Box style={createuomwebStyle.adduomsideBar}>
                    <Sidebar />
                </Box>
                <Box style={createuomwebStyle.adduomcontent}>
                    <ToastContainer />
                    <AppHeader pageTitle="Configuration" />
                    <Box style={createuomwebStyle.parentBox}>
                        <Box style={createuomwebStyle.adduomBlock}>
                            <Grid container spacing={0} >
                                <Grid item sm={12} className='left-center-align'>
                                    <Typography style={createuomwebStyle.adduomHeading}>{configJSON1.addUomHeading}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className={`${this.loaderBoxFnc()}`}>
                            {this.loaderBoxContentFnc()}
                        </Box>
                        <Box style={{ padding: "15px 0" }} className={`${this.loaderBoxFnc() == 'loaderBox' && 'display-none'}`}>
                            <Grid container spacing={0} >

                                <Grid item sm={12}>
                                    <Grid item sm={6}>
                                        <Box sx={{ padding: "0 15px" }}>
                                            {!this.state.isEditing ? <Typography >
                                                {configJSON1.uomCategory}
                                                <span className={'required'}>*</span>
                                                <span style={createuomwebStyle.separator}> | </span> {this.state.category}
                                            </Typography>
                                                : <><Typography className={`${!this.state.category ? 'error-text-pos' : ''}`}>
                                                    {configJSON1.uomCategory}
                                                    <span className={`${!this.state.category ? 'error-text-pos' : 'required'}`}>*</span>
                                                </Typography>
                                                    <Box className='relative'>
                                                        <TextField data-test-id="uomCategoryInput"
                                                            placeholder='Enter UoM Category'
                                                            className={`form-input width-100 ${!this.state.category ? 'error-focus' : ''}`}
                                                            disabled={!this.state.isEditing}
                                                            value={this.state.category}
                                                            error={!this.state.category}
                                                            onChange={(e: any) => {
                                                                this.setState({
                                                                    category: e.target.value
                                                                })
                                                            }}
                                                            name="category" type="text" />
                                                        <Typography className={`main-color bold-text`} style={createuomwebStyle.languageText}>{configJSON1.en}</Typography>
                                                        {!this.state.category && <Box className={`flex relative `}>
                                                            <img className='error-text-img' src={validationerror} />
                                                            <Typography className='error-text'>{configJSON1.categoryMessage}</Typography>
                                                        </Box>}
                                                    </Box></>}
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6}>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} >
                                <Grid item sm={12} className='account_details' >
                                    <Typography className='main-color bold-text'>{configJSON1.addUomTh1}</Typography>
                                </Grid>
                                <Grid item sm={12} className='bank-grid' >
                                    <Grid item sm={3}  >
                                        <Typography className='main-color bold-text'>{configJSON1.addUomTh2}</Typography>
                                    </Grid>
                                    <Grid item sm={4}  >
                                        <Typography className='main-color bold-text'>{configJSON1.addUomTh3}</Typography>
                                    </Grid>
                                    <Grid item sm={2}  >
                                        <Typography className='main-color bold-text'>{configJSON1.addUomTh4}</Typography>
                                    </Grid>
                                    <Grid item sm={2}  >
                                        <Typography className='main-color bold-text'>{configJSON1.addUomTh5}</Typography>
                                    </Grid>
                                    <Grid item sm={1} className='end-center-align' data-test-id="add-row">
                                        {!this.state.isEditing ? <img aria-readonly src={addrow} /> :
                                            <img
                                                onClick={this.addAnotherColumn}
                                                src={addrow} />}
                                    </Grid>
                                </Grid>
                                <Grid item sm={12} style={{ padding: 0 }}  >
                                    <hr className='grid-hr' />
                                </Grid>
                                {
                                    this.state.tableData?.map((detail: any, index: number) => {
                                        return <>
                                            <Grid item sm={12} className='account-grid'  >
                                                <Grid item sm={3} className='pl-5 left-center-align'>
                                                    <FormControl id="select-form-control" className="width-100 account-tab-select">
                                                        {!this.state.isEditing ? this.getText(detail?.uom) :
                                                            <Select
                                                                IconComponent={this.expandMore}
                                                                data-test-id="uomInput"
                                                                inputProps={{
                                                                    'aria-label': 'custom select',
                                                                }}
                                                                value={detail.uom}
                                                                onChange={(e: any) => this.handleMeasureUnitsDetails(e, index, 'uom')}
                                                                label="Select"
                                                                disableUnderline
                                                                className="width-100"
                                                                MenuProps={{
                                                                    PaperProps: {
                                                                        style: {
                                                                            marginTop: 52,
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                {
                                                                    this.uomOption()
                                                                }

                                                            </Select>}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item sm={4} className='left-center-align' >
                                                    <FormControl id="select-form-control" className="width-100 account-tab-select">
                                                        {!this.state.isEditing ? this.getText(detail?.uom_type) :
                                                            <Select
                                                                IconComponent={this.expandMore}
                                                                data-test-id="uomTypeInput"
                                                                inputProps={{
                                                                    'aria-label': 'custom select',
                                                                }}
                                                                value={detail.uom_type}
                                                                onChange={(e: any) => this.handleMeasureUnitsDetails(e, index, 'uom_type')}
                                                                label="Select"
                                                                disableUnderline
                                                                className="width-100 uom-type-select"
                                                                MenuProps={{
                                                                    PaperProps: {
                                                                        style: {
                                                                            marginTop: 52,
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem value="Reference Unit of Measure for this category">Reference Unit of Measure for this category</MenuItem>
                                                                <MenuItem value="Smaller than the reference Unit of Measure for this category">Smaller than the reference Unit of Measure for this category</MenuItem>
                                                                <MenuItem value="Bigger than the reference Unit of Measure for this category">Bigger than the reference Unit of Measure for this category</MenuItem>

                                                            </Select>}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item sm={2} className='left-center-align' data-test-id="getRatio">

                                                    {!this.state.isEditing ? this.getText(detail?.ratio) :

                                                        this.getRatioColumn(detail.uom_type, detail.ratio, index, null, '1.00000', 'ratio')
                                                    }
                                                </Grid>
                                                <Grid item sm={2} className='left-center-align' >
                                                    {!this.state.isEditing ? this.getText(detail?.rounding_precision) :
                                                        this.getRatioColumn(detail.uom_type, detail.rounding_precision, index, null, '0.01000', 'rounding_precision')
                                                    }
                                                </Grid>
                                                <Grid item sm={1} className='end-center-align delrow'>
                                                    {!this.state.isEditing ? <img aria-readonly src={delrow} /> :
                                                        <Box data-test-id="del-row1" onClick={() => this.removeColumn(index)}>
                                                            <img
                                                                src={delrow} />
                                                        </Box>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={12} style={{ padding: 0 }}  >
                                                <hr className='grid-hr' />
                                            </Grid>
                                        </>
                                    })
                                }

                            </Grid>
                        </Box>
                    </Box>
                    <Box className='end-center-align' style={{ margin: "20px 0" }}>
                        {!this.state.isEditing ?
                            <Link to="/AddUomCategories">
                                <Button style={createuomwebStyle.createBtn}
                                    data-test-id="create-uom-category" variant="outlined">
                                    {configJSONUtils.create}
                                </Button>
                            </Link> :
                            <Link to="/UomCategorieslistview">
                                <Button style={createuomwebStyle.discardBtn} data-test-id="discard-uom-category" variant="outlined">
                                    {configJSONUtils.discard}
                                </Button>
                            </Link>
                        }
                        {!this.state.isEditing ? <Button onClick={() => this.setState({ isEditing: true })} data-test-id="edit-uom-category" variant="contained" style={{ backgroundColor: "#026494", color: "#ffffff" }}>{configJSON1.edit}</Button>
                            : <Button onClick={this.saveUomCategories} data-test-id="save-uom-category" variant="contained" style={{ backgroundColor: "#026494", color: "#ffffff" }}>{configJSONUtils.save}</Button>}

                    </Box>
                </Box>
            </Box>
        );
    }
}

export const createuomwebStyle: any = {
    adduomMainLayout: {
        display: "flex",
    },
    adduomsideBar: {
        display: "flex",
        minHeight: "100%",
        flexGrow: 1,
        transition: "all 0.3s",
    },
    adduomcontent: {
        flexGrow: 9,
        padding: "0 20px",
    },

    adduomBlock: {
        padding: "15px",
        borderBottom: "1px solid #e3e3e3"
    },
    parentBox: {
        border: "1px solid #e3e3e3",
        borderRadius: "5px",
        backgroundColor: "white"
    },
    adduomHeading: {
        color: "#006395",
        fontSize: "30px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    pb10: {
        paddingBottom: "10px !important"
    },
    discardBtn: {
        marginRight: "15px",
        backgroundColor: "white",
        border: "1px solid #e6e6e6"
    },
    createBtn: {
        marginRight: "15px",
        backgroundColor: "white",
        border: "1px solid #e6e6e6"
    },
    languageText: {
        position: "absolute",
        right: 20,
        top: 20
    },
    ratioStyle: {
        paddingRight: "30px",
        marginLeft: "-5px",
    },

    roundingStyle: {
        paddingRight: "30px",
        marginLeft: "-10px",
    },
    separator: {
        marginLeft: "10px",
        marginRight: "10px",
        color: "#BDBABA"
    }

}

export const EditUoMCategoriesView = withStyles(createuomwebStyle)(EditUoMCategories)
