import React from "react";
import StorageProvider from "../../../../framework/src/StorageProvider";
import { CircularProgress } from "@material-ui/core";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import { toast } from 'react-toastify';
import { Message } from "../../../../framework/src/Message";
const configJSON1 = require('./config');
const configJSONUtils = require('../config');

interface TableData {
  uom: string;
  uom_type: string;
  ratio: string;
  rounding_precision: string;
  active: boolean;
  archive: boolean;
}

interface UOMAttributes {
  uom: string;
  archive: boolean;
}

interface UOM {
  id: string;
  type: string;
  attributes: UOMAttributes;
}

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

export interface S {
  token: string;
  category: string;
  isEditing: boolean;
  loading: boolean;
  uomOptions: UOM[];
  tableData: TableData[];
  delRowIndex: number | string;
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}
export class EditUoMController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  deleteRowApiCallId!: string;
  addUomCategoryApiCallId!: string;
  getUomDataAPI!: string;
  getUomAPI!: string;
  apigetUOMFilterlistCallId!: string;


  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationMessage)
    ];

    this.state = {
      token: "",
      category: "",
      isEditing: false,
      loading: false,
      uomOptions: [],
      tableData: [],
      delRowIndex: ''
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, msg: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === msg.id) {
      const apiRequestCallId = msg.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = msg.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = msg.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      console.log({ errorReponse });
      runEngine.debugLog("API Message Recived", msg);
      console.log('before', responseJson);

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.deleteRowApiCallId) {
          this.handleDeleteRowApi(responseJson)
        }
       else if (apiRequestCallId === this.addUomCategoryApiCallId) {
          this.handleSaveUomApi(responseJson)
        }
       else if (apiRequestCallId === this.getUomDataAPI) {
          this.handleGetUomResponse(responseJson)
        }
       else if (apiRequestCallId === this.getUomAPI) {
          this.handleGetUomOptionResponse(responseJson)
        }
      }
    }
    // Customizable Area End
  }
  async componentDidMount() {
    await this.fetchToken();
    this.fetchUoMData()
    this.fetchData();
  }

  fetchToken = async () => {
    const token = await StorageProvider.get('token');
    if (token) {
      this.setState({
        token: token
      });
    }
    else {
      const token = localStorage.getItem('TOKEN');
      if (token)
        this.setState({
          token: token
        });
    }
    this.setState({
      loading: false
    })
  }

  handleGetUomResponse = (responseJson: any) => {
    if (responseJson.data?.data?.attributes) {
      this.setState({
        tableData: responseJson.data.data?.attributes?.unit_of_measures,
        category: responseJson.data.data?.attributes?.category,
        loading: false
      })
    }
    else if (responseJson.errors) {
      toast.error(responseJson.errors[0])
      this.setState({
        loading: false
      });
    }
  }

  handleGetUomOptionResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        uomOptions: responseJson.data,
        loading: false
      })
    }
    else if (responseJson.errors) {
      toast.error(responseJson.errors[0])
      this.setState({
        loading: false
      });
    }
  }

  fetchData = async () => {
    const id = this.props.navigation.getParam("id");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": "application/json",
      "token": this.state.token
    };

    this.getUomDataAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON1.viewUrl}${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  fetchUoMData = async () => {
    this.setState({
      loading: true
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": "application/json",
      "token": this.state.token
    };

    this.getUomAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON1.uomUrl}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDeleteRowApi = (responseJson: any) => {
    if (responseJson.message) {
      toast.success(responseJson.message)
      const updatedTableData = this.state.tableData.filter((i: any, indx: number) => indx != this.state.delRowIndex)
      this.setState({
        tableData: updatedTableData
      });
    }
    else if (responseJson.errors) {
      toast.error(responseJson.errors[0])
    }
    this.setState({
      loading: false
    });
  }

  handleSaveUomApi = (responseJson: any) => {
    if (responseJson?.data) {
      this.props.navigation.navigate("UomCategorieslistview")
    } else if (responseJson.errors) {
      toast.error(responseJson.errors[0])
    }
    this.setState({
      loading: false
    });
  }
  handleUomType = (newArr: any, val: any) => {
    if (newArr.filter((i: any) => i.uom_type == configJSONUtils.uomOption1).length == 1 && newArr.length > 1 && val == configJSONUtils.uomOption1) {
      window.alert("You can select this type only one time");
      return false;
    }
    return true;
  }

  removeColumn = (index: any) => {
    if (this.state.tableData.length > 1) {
      const removedItem: any = this.state.tableData.find((_, indx: number) => indx === index);
      this.deleteRow(removedItem.id, index)
    } else {
      window.alert("UoM category weight must have atleast one reference unit of measure.");
    }

  }

  addAnotherColumn = () => {
    const updatedTableData: any[] = [...this.state.tableData];
    const obj = {
      uom: "",
      uom_type: "",
      ratio: "",
      rounding_precision: "",
      active: true,
      archive: true
    };
    updatedTableData.push(obj);
    this.setState({
      tableData: updatedTableData
    });
  }

  handleMeasureUnitsDetails = (e: any, index: number, field: string) => {
    const val = e.target?.value;
    let newArr = JSON.parse(JSON.stringify(this.state.tableData));
    const editArr: any = newArr.filter((i: any, indx: number) => indx == index)
    if (!this.handleUomType(newArr, val)) {
      return;
    }
    if (editArr?.length > 0) {
      editArr[0][field] = val;
      if (val == configJSONUtils.uomOption1) {
        editArr[0]['ratio'] = 1.00000;
        editArr[0]['rounding_precision'] = 0.01000;
      } else if (editArr[0]['ratio'] == 1.00000 && editArr[0]['rounding_precision'] == 0.01000) {

        editArr[0]['ratio'] = "";
        editArr[0]['rounding_precision'] = "";

      }
    }
    this.setState({
      tableData: newArr
    });
  }

  checkUomValidation = () => {
    if (!this.state.category) {
      return false;
    }
    else if (!this.state.tableData.filter((i: any) => i.uom_type == configJSONUtils.uomOption1).length) {
      window.alert("UoM category weight must have atleast one reference unit of measure.");
      return false;
    }
    return true;
  }

  saveUomCategories = async () => {
    const id = this.props.navigation.getParam("id");
    if (!this.checkUomValidation()) {
      return false;
    }
    this.setState({
      loading: true
    });
    let formdata: any = new FormData();
    formdata.append("data[category]", this.state.category);
    formdata.append("data[unspsc_category]", null);
    this.state.tableData.length > 0 && this.state.tableData.forEach((item: any, index: number) => {
      Object.keys(item).forEach((key) => {
        formdata.append(`data[unit_of_measures_attributes][${index}][${key}]`, item[key]);
      });
    })
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addUomCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON1.viewUrl}${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PATCH'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteRow = async (rowId: string, index: number) => {
    if (!rowId) {
      const updatedTableData = this.state.tableData.filter((i: any, indx: number) => indx != index)
      this.setState({
        tableData: updatedTableData
      });
      return
    }
    this.setState({
      delRowIndex: index
    });
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteRowApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON1.deleteRowUrl}${rowId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  loaderBoxFnc = () => {
    if (this.state.loading || this.state.tableData.length == 0) {
      return 'loaderBox'
    } else {
      return 'display-none'
    }
  }

  loaderBoxContentFnc = () => {
    if (this.state.loading  || this.state.tableData.length == 0) {
      return <CircularProgress />
    } else {
      return <></>
    }
  }

}
