// Customizable Area Start
import React from 'react';
import { Box, Button, Grid, Typography, withStyles } from '@material-ui/core';
export const configJSON = require("./config");
import Sidebar from '../../../components/src/Sidebar.web';
import { AppHeader } from '../../../components/src/AppHeader.web';
import BankViewModeController, {Props} from './BankViewModeController.web';
import { settingsGrey } from '../../itemavailability/src/assets';
import { Link }  from 'react-router-dom';


// Customizable Area End


export class BankViewMode extends BankViewModeController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const { classes }: any = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            
            <Box className="gridMainLayout">
            <Box className="gridsideBar">
            <Sidebar />
            </Box>
            
            <Box className="gridcontent" >
            <AppHeader pageTitle={configJSON.contacts} />
            <Box >
                <Box className='child-div-1'>
                <Grid container spacing={0} >
                <Grid item sm={12}  className={`grid-item-1 ${classes.gridItemBox}`}>
                <Grid item sm={10}  >
                <Box>
                <Typography>
                    <Link to = "/BanksListView" 
                    className="companiesHeading bold-text" 
                    style={{ textDecoration: 'none' }} >
                    {configJSON.banks}
                    </Link>
                    <span className='new-normal-text'>
                        / {configJSON.new}
                    </span>
                </Typography>
                </Box>
                </Grid>
                <Grid item sm={2} className='end-center-align' >
                <Button className={`filters-left ${classes.settingsIcon}`} data-testid="list-settings"><img src={settingsGrey} /></Button>
                </Grid>
                </Grid>
                
                </Grid>
                </Box>
                
                <Box className={`form-div form-div-border bottomBorder ${classes.bottomBorder}`}>
                
                <Grid container  >
                
                <Grid item sm={6} >

                    {
                        this.boxUI(`Bank Name`,this.state.showBankdata?.bank_name,classes)
                    }
                    {
                        this.boxUI(`Bank Identifier Code`,this.state.showBankdata?.bank_identifier_code,classes)
                    }
                    {
                        this.boxUI(`ABM Code`,this.state.showBankdata?.abm_code,classes)
                    }
                    {
                        this.boxUI(`Phone Number`,this.state.showBankdata?.phone_number,classes)
                    }
                    {
                        this.boxUI(`Email Address`,this.state.showBankdata?.email_address,classes)
                    }
                    {
                        this.boxUI(`VAT`,this.state.showBankdata?.vat,classes)
                    }

                </Grid>
                <Grid item sm={6} >

                    {
                        this.boxUI(`Address (line 1)`,this.state.showBankdata?.address?.split('@')[0],classes)
                    }
                    {
                        this.boxUI(`Address (line 2)`,this.state.showBankdata?.address?.split('@')[1],classes)
                    }
                    {
                        this.boxUI(`Country`,this.state.showBankdata?.country?.split('@')[0],classes)
                    }
                    {
                        this.boxUI(`State`,this.state.showBankdata?.state?.split('@')[0],classes)
                    }
                    {
                        this.boxUI(`City`,this.state.showBankdata?.city?.split('@')[0],classes)
                    }
                    {
                        this.boxUI(`Postal Code`,this.state.showBankdata?.postal_code,classes)
                    }

                </Grid>
                
            
                </Grid>
                </Box>
                
                <Box className='end-center-align form-btn-box'>
                <Button variant="outlined" onClick={this.navigatetoAddBank} data-test-id="create-button" className='discard-btn' >{configJSON.create}</Button>
                <Button variant="contained" onClick={this.navigatetoEditBank} data-test-id="edit-button" className='save-btn' >{configJSON.edit}</Button>
                
                </Box>
            </Box>
            
            </Box>
            </Box>
            </>
            
            // Customizable Area End
        )

    }
}

// Customizable Area Start
export const userlistwebStyle: any = {
    bottomBorder: {
        border: "1px solid #e3e3e3 !important",
    },
    boxMargin: {
        marginTop:"5px !important",
        marginBottom:"15px !important"
    },
    settingsIcon: {
        margin: "15px 20px !important",
        padding: "10px 8px !important",
        minWidth: "45px !important"
    },
    gridItemBox: {
        padding: "5px 0 5px 15px"
    }
    
    
}
export default withStyles(userlistwebStyle)(BankViewMode)
  // Customizable Area End